.filterInItem {
  display: flex;
  justify-content: space-between;
  margin: 0 27px;
  position: relative;
  margin-bottom: 24px;
}

.notification {
  display: flex;
  justify-content: flex-end;
  /* margin-right: 11px; */
}

.notification:active {
  opacity: 0.3;
}
.inputImgSearch {
  position: absolute;
  left: 12px;
  top: 10px;
}

.inputSearch {
  width: 71%;
  height: 40px;
  padding-left: 35px;
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  background: none;
}

.inputSearch::placeholder {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  color: #a8a8a8;
}

.dropdown_filters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 40px;
  background: #ff7a00;
  border-radius: 5px;
}

.dropdown_filters:active {
  opacity: 0.3;
}

.all_items {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */

  margin: 26px 26px;
}

.open_filter {
  display: flex;
  flex-direction: column;
  margin: 0 27px;
}

.filter_last_line {
  display: flex;
}
.filter_line_trade_house {
  display: flex;
  justify-content: space-between;
}
.trade_house_select,
.corpus_select,
.shop_select,
.product_filter_select {
  width: 264px;
  height: 40px;
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  background: none;
  margin-bottom: 24px;
  font-family: var(--GothamProRegular);
  padding-left: 7px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #404246;
}

.corpus_select {
  width: 72px;
}
.shop_select {
  width: 100%;
}
.button_filter_svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 40px;
  border: 0.5px solid #404246;
  border-radius: 5px;
}

.button_filter_svg:active {
  opacity: 0.3;
}

.btnFilter {
  width: 161px;
  display: inline-block;
}

.btnWrapper {
  display: flex;
  justify-content: space-around;
}
.carousel.control-dots {
  margin: -15px 0;
}

.wrapHeader {
  display: flex;
  flex-direction: column;
}
.shopAddres {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin: 16px 0px;
  color: #404246;
}

.slide {
  width: 360px;
  height: 240px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  margin: 0px 27px;
  border-radius: 5px;
  position: relative;
}

.slide_previous {
  width: 360px;
  height: 240px;
}

.slide_previous img {
  width: 100%;
  height: 100%;
}
.categoryesToShop {
  display: flex;
  align-items: center;
  overflow: scroll;
}

.dataShop {
  margin: 0 29px;
}
.categoryToShop {
  border: 0.5px solid #404246;
  border-radius: 5px;
  padding: 14px 16px;
  margin-right: 16px;
  white-space: nowrap;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;

  color: #404246;
}
.categoryToShopActive {
  border: 0.5px solid rgb(255, 122, 0);
  border-radius: 5px;
  padding: 14px 16px;
  margin-right: 16px;
  white-space: nowrap;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  background-color: rgb(255, 122, 0);
  color: #fff;
}
@media (max-width: 423px) {
  /* .all_items {
    margin-left: 10px;
  } */
  .dropdown_filters {
    margin-left: 5px;
  }
}

@media (max-width: 400px) {
  .slide {
    margin: 0 14px;
  }
}

@media (max-width: 353px) {
  .slide_previous {
    width: 300px;
    height: 200px;
  }
  .slide {
    height: 200px;
    margin: 0 12px;
  }
}
