.datepicker-dateList {
    display: flex;
    margin: 2px 0 2px 0;
  }
  
  .datepicker-date-day-Item {
    /* font-weight: bold; */
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    /* margin: 0 12px 0 12px; */
    width: 35px;
    height: 25px;
    flex-shrink: 0;
  /* border-radius: 50%; */
  margin-top: 8px;
  /* border: 2px solid #fdfbfb;  */
  /* color: #e6545b; */
  }
  
  .datepicker-button-previous-wrapper {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 0 0 0 12px;
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    border-radius: 50%;
    /* color: #e6545b; */
  }
  
  .datepicker-strip {
    display: flex;
    /*align-items: center;*/
    flex-direction: column;
    margin-top: 10px;
    width: max-content;
    max-width: 100%;
    overflow: hidden;
    line-height: 1.5;
    font-family: sans-serif;
  }
  
  .date-day-Item-selected {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid #e6545b;
    color: #e6545b;
  }
  
  .datepicker-date-day-Item:hover {
    /* background-color: #e6545b;
    border: 2px solid transparent;
    color: #fff;
    width: 45px;
    height: 45px;
    border-radius: 50%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    height: 30px;
    width: 35px;
    margin-top: 1px;
    border-radius: 100%;
    background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
    justify-content: center;
    color: aliceblue;
  }
  
  .date-day-item-disabled {
    color: rgb(176, 176, 176);
    pointer-events: none;
  }
  
  .datepicker-datelist-scrollable {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    /* margin: 2px 0 2px 0; */
  }
  
  .datepicker-datelist-scrollable::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }
  
  .datepicker {
    /* display: flex;
      margin: 12px;
      align-items: center;
      position: relative; */
  }
  
  .datepicker-day-label {
    font-size: 12px;
    /* margin: 4px 0 0 0; */
    text-align: center;
    padding: 0.5em !important;
    background: linear-gradient(72deg, #f4a582 20%, #ff608d 51.56%, #fa00ff 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  }
  
  .scroll-head {
    border: 1px solid #ffffff;
    border-radius: 2px;
    margin: 8px 0px 8px 0px;
    text-align: center;
    height: 18px;
    font-size: 12px;
    background: #ffffff;
    /* display: none; */
  }
  .blank-space-div {
    border: 1px solid transparent;
    border-radius: 2px;
    margin: 8px 0px 8px 0px;
    text-align: center;
    height: 18px;
    font-size: 12px;
    display: none;
  }
  
  .datepicker-button-previous,
  .datepicker-button-next {
    border: none;
    text-decoration: none;
    background: #e6545b;
    cursor: pointer;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    flex-shrink: 0;
    display: none;
  }
  
  .button-previous {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 2px solid transparent;
    /* color: #e6545b; */
    display: none;
  }
  
  .datepicker-button-previous {
    transform: rotate(180deg);
  }
  
  .datepicker-month-label {
    /* margin-left: 12px; */
    font-size: 13px;
    /* color: #e6545b; */
    /* font-weight    : bold; */
    margin-top: 5px;
    margin-bottom: 15px;
    display: none;
  }
  
  .datepicker-date-label {
    /* font-size: 12px; */
    margin-top: -3px;
  }
  .wrapper {
    position: relative;
    /* border: 1px solid #000; */
  }
  @-webkit-keyframes ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0.9;
    }
    100% {
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  
  @keyframes ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0.9;
    }
    100% {
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  .ripple:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: currentColor;
    visibility: hidden;
    z-index: 2;
  }
  .ripple:not(:active):before {
    -webkit-animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
    animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
    transition: visibility 0.9s step-end;
  }
  .ripple:active:before {
    visibility: visible;
  }
  
  .weekendsDay {
    width: 35px;
    height: 45px;
    border-radius: 50%;
    /* border: 2px solid #c0b7b7; */
    color: #c0b7b7;
    margin-top: 8px;
  }

  .daysWithAppointmentDay {
    width: 35px;
    height: 25px;
    margin-top: 8px;
    border-radius: 50%;
    /* border: 2px solid #f809c4;
    color: #f809c4; */
    background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  }

  .today {

    border: double 3px transparent;
    border-radius: 30px;
    background-image: linear-gradient(white, white), 
    linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  .todayDate {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    height: 25px;
    border-radius: 100%;
    background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
    justify-content: center;
    color: aliceblue;
  }

.scroll-head-disabple {
    display: none;
}