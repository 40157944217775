.sort{
    width: 12px;
}

button{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.btn_close_modal{
    background-color: #f67c01;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 12px;
    font-size: 20px;
    padding: 10px 40px;
    margin: 0 auto;
    margin: 30px 0;
}

.btn-pay{
    background-color: #f67c01;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 12px;
    font-size: 20px;
    margin: 0 auto;
    padding: 15px 0;
    width: 100%;
    margin: 30px 0;
}

.btn-backet-minus, .btn-backet-plus{
    width: 48px;
    height: 48px;
    outline: none;
    font-size: 32px;
    color: #707070;
    border: 1px solid #c5c5c5;
    border-radius: 13px;
}

.btn_basket_card{
    background-color: #f67c01;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 12px;
    font-size: 18px;
    padding: 0 10px;
    margin-left: 20px;
    height: 40px;
    width: 100%;
}

.btn_basket_card_null{
    background-color: #ffc58a;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 12px;
    font-size: 18px;
    padding: 0 10px;
    margin-left: 20px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
}

.btn-backet-minus-card, .btn-backet-plus-card{
    width: 40px;
    height: 40px;
    outline: none;
    font-size: 32px;
    color: #707070;
    border: 1px solid #c5c5c5;
    border-radius: 13px;
}

.none-btn{
    height: 40px;
    width: 40px;
    opacity: 0;
}

.btn_reg_cabinet{
    background-color: #f67c01;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 18px;
    font-size: 14px;
    padding: 5px 0;
    width: 100%;
}

.btn-category-cafe{
    background-color: #c0c0c0;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 14px;
    padding: 2px 7px;
    cursor: pointer;
    font-size: 18px;
    min-width: 90px;
}

.reload_error_page{
    background-color: #f67c01;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 8px;
    padding: 10px 48px;
    cursor: pointer;
    font-size: 18px;
}

.btn_send-sms{
    background-color: rgb(76, 0, 255);
    color: #fff;
    outline: none;
    border: none;
    border-radius: 18px;
    font-size: 18px;
    padding: 5px 0;
    width: 100%;
    margin-bottom: 25px;
}

.btn_add_adress{
    background-color: #f67c01;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 12px;
    font-size: 18px;
    padding: 0 10px;
    height: 40px;
    width: 100%;
}

.btn_del_adress_basket{
    outline: none;
    border: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: tomato;
    color: #fff;
    transform: rotate(45deg);
}

.btn-search-category-cafe{
    outline: none;
    border: none;
    padding: 5px;
    border-radius: 50%;
    background-color: #c0c0c0;
    margin-right: 5px;
    fill: #fff;
}

.btn_info_for_partners{
    width: 100%;
    background-color: rgb(43, 40, 255);
    color: #fff;
    outline: none;
    border: none;
    font-size: 16px;
    padding: 8px 0;
    border-radius: 8px 8px 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;
}

.btn_closePopUpPartner{
    background: none;
    color: rgb(43, 40, 255);
    outline: none;
    border: none;
    font-size: 16px;
    align-self: flex-end;
    padding: 10px;
}

.btn_stayPartner{
    background-color: rgb(43, 40, 255);
    color: #fff;
    outline: none;
    border: none;
    font-size: 16px;
    padding: 8px 28px;
    border-radius: 8px;
    margin: 18px 0 40px 0;
    cursor: pointer;
}

.btn-formPartner{
    background-color: rgb(43, 40, 255);
    color: #fff;
    outline: none;
    border: none;
    font-size: 16px;
    padding: 8px 28px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-prev-formPartner{
    background: none;
    color: rgb(43, 40, 255);
    outline: none;
    border: none;
    font-size: 18px;
}