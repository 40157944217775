:root {
  --malina: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  --lgradient: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(to right, #edcd73, #ff015f) border-box;
  --text-gradient: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%) border-box;
}

* {
  font-family: var(--GothamProRegular);
}

html {
  overflow-x: hidden;
}

.root {
  margin: 0px 23px 100px 23px;
  position: relative;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;

  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
}

.addTime {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  padding: 5px;
  color: #f3f3f3;
  margin-top: 20px;
  border: none;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  margin: 0 auto;
}

.addTime:active {
  opacity: 0.7;
}

.inputTime {
  width: 155px;
  height: 40px;
  font-size: 20px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
}

.wrapperTimeInput {
  width: 59%;
  display: flex;
  justify-content: center;
}

.date {
  position: relative;
  width: 100vw;
  height: 32px;
  margin-top: 20px;
  left: -23px;
  /* Малиновый */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */

  color: #ffffff;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  padding-top: 5px;
  padding-left: 20px;
}

.editButton {
  position: absolute;
  right: 18px;
  top: 8px;
  font-size: 24px;
}

.editButtonActive {
  color: #292929;
  position: absolute;
  right: 18px;
  top: 8px;
  font-size: 24px;
}

.itemTime {
  width: 68px;
  height: 32px;
  border: 1px solid #a8a8a8;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-right: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemTime span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  color: #404246;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperTime {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.wrapForm {
  display: flex;
  align-items: center;
}

.itemTimeActive {
  position: relative;
  width: 68px;
  height: 28px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: white;
  box-sizing: border-box;
  margin-top: 2px;
}

.itemTimeActive::before {
  content: '';
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  z-index: -1;
}

.itemTimeAccepted {
  position: relative;
  width: 68px;
  height: 28px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #fff;
  box-sizing: border-box;
  margin-top: 2px;
}

.itemTimeAccepted::before {
  content: '';
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: #0f920f;
  border-radius: 5px;
  z-index: -1;
}

.itemTimeDone {
  position: relative;
  width: 68px;
  height: 28px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #fff;
  box-sizing: border-box;
  margin-top: 2px;
}

.itemTimeDone::before {
  content: '';
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: #d432d8;
  border-radius: 5px;
  z-index: -1;
}

.wrapperModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 10px 0;
  background-color: #4042468f;
}

.modal {
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #f3f3f3;
  width: 337px;
  border-radius: 10px;
  box-shadow: 0 0 4px 2px #00000044;
  padding: 42px 24px;
  color: #404246;
}

.closeModal {
  display: flex;
  justify-content: flex-end;
  font-size: 23px;
}

.wrapForm {
  display: flex;
}

.img {
  align-items: center;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 50px;
  display: flex;
  height: 89px;
  justify-content: center;
  margin-right: 16px;
  position: relative;
  width: 89px;
  margin-bottom: 10px;
}

.img img {
  border-radius: 50px;
  height: 90%;
  object-fit: cover;
  overflow: hidden;
  width: 90%;
}

.namePerson {
  width: 89px;
  text-align: center;
  word-break: break-word;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 12px;
  /* identical to box height, or 100% */

  color: #404246;
}

.catOrder,
.nameOrder,
.dataOrder,
.timeOrder,
.priceOrder {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  /* identical to box height, or 100% */
  margin-bottom: 13px;
  color: #404246;
}

.nameOrder,
.dataOrder,
.timeOrder,
.priceOrder {
  font-weight: 400;
}

.dataOrder label {
  margin-right: 20px;
}

.timeOrder label {
  margin-right: 10px;
}

.priceOrder label {
  margin-right: 20px;
}

.wrapButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}

.buttonAccept {
  width: 137px;
  height: 30px;
  left: calc(50% - 137px / 2 + 115.5px);
  top: calc(50% - 30px / 2 - 111.5px);

  /* Малиновый */
  border: none;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;

  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  color: #ffffff;
}

.buttonCancel {
  position: relative;
  width: 135px;
  height: 27px;

  border-radius: 5px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  border: none;
  background-color: var(--mainColor);
  color: #292929;
  margin-top: 1px;
}

.buttonCancel::before {
  content: '';
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  z-index: -1;
}

.ScheduleHeader {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
}

.DatePicker {
  font-family: sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

.wrapperButtons {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;
}

.btn {
  padding: 10px 24px;
  border: none;
  border-radius: 5px;
  box-sizing: content-box;
  font-weight: 400;
}

.btnFill {
  background: var(--malina);
  color: #e5e5e5;
}

.btnOutline {
  background: var(--text-gradient);
  border: 1px solid transparent;
}

.btnOutline::before {
  content: attr(data);
  background: var(--malina);
  -webkit-background-clip: text;
  color: transparent;
}

.card {
  margin: 0 auto;
  margin-top: 5%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #f3f3f3;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 0 4px 2px #00000044;
  padding: 42px 24px;
  color: #404246;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.title {
  font-size: 16px;
}

.row label {
  margin-bottom: 6px;
  font-size: 15px;
}

.row input,
select {
  background: #f3f3f3;
  padding: 8px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid #00000044;
}

.row input::placeholder {
  color: #ccc;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
  text-align: center;
}

#time {
  width: 80px;
}

#date {
  width: 110px;
}

#price {
  width: 80px;
}

.btnExit {
  position: absolute;
  right: 16px;
  top: 12px;
  border: none;
  font-weight: bold;
  font-size: 18px;
}

.btnSubmit {
  color: #fff;
  font-weight: bold;
}

.divider {
  margin-top: 25px;
  border: 1px solid transparent;
  -moz-border-image: -moz-linear-gradient(266.64deg,
      #d432d8 -0.07%,
      #ff608d 51.49%,
      #f4a582 99.93%);
  -webkit-border-image: -webkit-linear-gradient(266.64deg,
      #d432d8 -0.07%,
      #ff608d 51.49%,
      #f4a582 99.93%);
  border-image: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-image-slice: 1;
  width: 85%;
  margin: auto;
}

.personalRec {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: start;
  margin-top: 20px;
}
