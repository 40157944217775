:root {
  --malina: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  --lgradient: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(to right, #edcd73, #ff015f) border-box;
  --text-gradient: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%) border-box;
}

* {
  font-family: var(--GothamProRegular);
}

.wrapperModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 10px 0;
  background-color: #4042468f;
}

.card {
  margin: 0 auto;
  margin-top: 25%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #f3f3f3;
  width: 337px;
  border-radius: 10px;
  box-shadow: 0 0 4px 2px #00000044;
  padding: 42px 24px;
  color: #404246;
}

.btnExit {
  position: absolute;
  right: 16px;
  top: 12px;
  border: none;
  font-weight: bold;
  font-size: 18px;
}

.btn {
  padding: 10px 24px;
  border: none;
  border-radius: 5px;
  box-sizing: content-box;
  font-weight: 400;
  margin-top: 24px;
}

.btnFill {
  background: var(--malina);
  color: #e5e5e5;
}

.btnSubmit {
  color: #fff;
  font-weight: bold;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  position: relative;
}

.row label {
  margin-bottom: 6px;
  font-size: 15px;
}

.row input,
select {
  background: #f3f3f3;
  padding: 8px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid #00000044;
}

.row input::placeholder {
  color: #ccc;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
}

.inputError {
  color: #ff4040;
  font-size: 13px;
  text-align: left;
  position: absolute;
  bottom: -15px;
}
