.root {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  background: #25272e;
  border: 1px solid #25272e;
  padding: 16px 44px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.root_beauty {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  background: #25272e;
  border: 1px solid #25272e;
  padding: 16px 44px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.footer__item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: 2px solid #f3f3f3;
  border-radius: 50%;
}
.footer__item:active,
.profile:active {
  opacity: 0.5;
}

.cart_footer_item {
  position: relative;
}

.coun_items_cart {
  text-align: center;
  position: absolute;
  color: #ffff;
  width: 20px;
  background-color: #ff7a00;
  border-radius: 50px;
  left: 25px;
  bottom: 22px;
  opacity: 0.8;
}

.profile img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
}
