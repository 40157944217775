.info {
    background: #e8e8e8;
    position: fixed;
    
    bottom: 0;
    min-height: 136px;
    box-shadow: 0px -2px 4px rgba(33,33,33,.3);
}

.info a{
    display: block;
    text-align: center;
    padding: 20px 0;
    color: #404246;
    text-decoration: none;
}
.text{
    font-size: 12px;
    overflow-y: scroll;
    transition: .5s;
    padding: 0 30px;
}
