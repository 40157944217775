:root{
    --button-primary: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
    --button-error: #dc3545;
    --button-success: #198754;
    --button-warning: #ffc107;
    --text-color: #e3e3e3;
    --text-color-black: #222;

}


.btn{
    padding: 5px 10px;
    border: none;
    box-shadow: 0 0 6px rgba(0,0,0,.5);
    font-size: 1rem;
    color: var(--text-color);
    border-radius: 4px;
}
.btn:disabled{
    opacity: .4;
}


/* Themes */

.primary {
    background: var(--button-primary);
}
.success {
    background: var(--button-success);
}
.error {
    background: var(--button-error);
}
.warning {
    background: var(--button-error);
    color: var(--text-color-black);
}
/* Sizes */

.small{
    font-size: 1rem;
}
.medium{
    padding: 10px 15px;
    font-size: 1.2rem;

}
.large{

    padding: 15px 20px;

    font-size: 1.4rem;

}

.fill{
    width: 100%;
}

.square{
    border-radius: 0;
}