@font-face {
  font-family: 'Gotham Pro Regular Italic';
  src: url('../assets/fonts/Gotham Pro/GothamPro-Italic.eot');
  src: url('../assets/fonts/Gotham Pro/GothamPro-Italic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Gotham Pro/GothamPro-Italic.woff') format('woff'),
    url('../assets/fonts/Gotham Pro/GothamPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Regular';
  src: url('../assets/fonts/Gotham Pro/GothamPro.eot');
  src: url('../assets/fonts/Gotham Pro/GothamPro.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Gotham Pro/GothamPro.woff') format('woff'),
    url('../assets/fonts/Gotham Pro/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Light';
  src: url('../assets/fonts/Gotham Pro/GothamPro-Light.eot');
  src: url('../assets/fonts/Gotham Pro/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Gotham Pro/GothamPro-Light.woff') format('woff'),
    url('../assets/fonts/Gotham Pro/GothamPro-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
