.item__bshop_image {
  position: relative;
}

.item__bshop_name {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 250px;
  text-align: left;
  padding-left: 10px;
  padding-top: 8px;
}
.addNewService:active {
  opacity: 0.4;
}
.item_client_req_info {
  width: 262px;
}
.item_client_zakaz_done {
  position: absolute;
  right: 5px;
  font-size: 14px;

  bottom: 5px;
  color: #404246;

  font-family: var(--GothamProRegular);
}

.item_client_req_info_name_id {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.item_client_req_info_name_serv {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #404246;
  width: 190px;
  margin-left: 15px;
  margin-top: 6px;
}

.item_client_req_info_name_cat {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  color: #404246;
  /* width: 65px; */
  margin-left: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.item_client_req_info_name_add {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  color: #404246;
  /* width: 65px; */
  margin-left: 17px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.item_client_req_info_comm_price {
  align-items: center;
  display: flex;
  margin-top: 4px;
}

.item_client_req_info_nameClient {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  color: #404246;
  margin-top: 7px;
  text-align: center;
  width: 80px;
  line-break: anywhere;
}
.app_title_wrapp {
  display: flex;
}
.app_title {
  font-family: var(--GothamProRegular);

  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  /* identical to box height, or 100% */

  color: #404246;
  margin-right: 5px;
}
.app_subtitle,
.app_info_order {
  font-family: var(--GothamProRegular);

  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;

  color: #404246;
}

.app_subtitle{
  display: flex;
  flex-wrap: wrap;
}

.app_info_order div {
  font-weight: 400;
  margin-top: 10px;
  margin-right: 10px;
}
.app_wrap_order_info {
  display: flex;
  position: relative;
}

.item_client_req_info_date {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 90px;
  margin-left: 5px;
  text-align: center;
  margin-right: 20px;
}

.item_client_req_info_time {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 35px;
  margin-left: 5px;
  margin-right: 5px;
}

.item_client_req_info_price {
  color: #404246;
  font-family: var(--GothamProRegular);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.item_client_req_info_id {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 10px;
}

.item_client_req_info_orderName {
  color: #404246;
  font-family: var(--GothamProRegular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  margin-top: 2px;
  text-align: left;
}

.item_client_req_info_comm {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 35px;
}

.item_client_req_info_phome {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 35px;
}

.item_bshop {
  display: inline-flex;
  background: #f3f3f3;
  box-shadow: 0px 6px 7px 1px rgb(34 60 80 / 16%);
  margin-right: 10px;
  margin-bottom: 20px;
  border: 0.4px solid transparent;
  width: 320px;
}

.item__bshop_image {
  margin-left: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 50px;
  margin-right: 16px;
  margin-left: 9px;
  display: flex;
}

.item__bshop_image img {
  overflow: hidden;
  border-radius: 50px;
  object-fit: cover;
  height: 90%;
  width: 90%;
}

.item__bshop_name {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #404246;
  width: 250px;
  text-align: left;
  padding-left: 10px;
  padding-top: 8px;
}

.dropdown_comm {
  position: absolute;
  top: 47%;
  right: 21px;
}

.dropdown_comm:active {
  opacity: 0.4;
}
.dropdown_accept {
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  margin-top: -20px;
  /* margin-left: 63%; */
  width: 137px;
  height: 30px;
  right: 22px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}

.modal {
  font-size: 12px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #a8a8a8;
  width: 355px;
  text-align: center;
  padding: 25px 15px;
  /* margin-top: 100px; */
  overflow: scroll;
  max-height: 90vh;
  min-height: 170px;
}

.modal_min {
  font-size: 12px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #a8a8a8;
  width: 355px;
  text-align: center;
  padding: 25px 23px;
  /* margin-top: 100px; */
}

.header_inner_modal {
  margin-top: 20px;
  display: flex;
}
.clientName {
  font-family: var(--GothamProRegular);

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */
  margin-top: 20px;
  color: #404246;
}

.modalIMGHead {
  margin-left: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 79px;
  height: 79px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 50px;
  margin-right: 16px;
  margin-left: 9px;
  display: flex;
}

.modalIMGHead img {
  overflow: hidden;
  border-radius: 50px;
  object-fit: cover;
  height: 90%;
  width: 90%;
}
.header {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  font-family: var(--GothamProRegular);

  /* Малиновый */

  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.content {
  margin: 10px auto;
  text-align: center;
  width: 95%;
}

.content_edit {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
}
.content_edit select {
  border-radius: 5px;
  /*  */
  margin-bottom: 10px;

  width: 310px;
  height: 35px;

  color: #404246c7;
  padding-left: 20px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0.5px solid #a8a8a8;
  background: transparent
    url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z' fill='black'/></g></svg>")
    no-repeat;
  background-position: right 5px top 50%;
}

.content_edit input {
  border-radius: 5px;
  /*  */
  margin-bottom: 10px;

  width: 310px;
  height: 35px;

  color: #404246c7;
  padding-left: 20px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0.5px solid #a8a8a8;
}
.actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 15px;
  top: 10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #ffffff;
  color: #404246;
}

.input_label {
  display: flex;
  width: 100%;
  font-family: var(--GothamProRegular);
}
.input_label input {
  width: 100%;
  margin-right: 8px;
}
.input_label_apply {
  display: flex;
  flex-direction: column;
  width: 314px;

  color: #404246;
  font-size: 16px;
}
.input_label_apply a {
  /* color: #f4a582 */
  font-size: 16px;
  text-decoration: none;
  font-weight: bold;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.input_label label {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;
  color: #404246;
  opacity: 0.7;
}

.input_label input,
.input_label textarea {
  padding: 5px 10px;
  height: 40px;
  border: 1px solid#A8A8A8;
  border-radius: 5px;
  background: none;
  font-family: var(--GothamProRegular);

  opacity: 0.7;
  margin-bottom: 20px;
}

.input_label label:focus,
.input_label input :focus {
  opacity: 1;
}

.dropdown_date {
  display: flex;
  align-items: center;

  justify-content: space-around;
  width: 100%;
  height: 40px;
  /* background: #ff7a00; */
  margin: auto;
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  background: none;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  color: #404246;
  /*  */
}

.dropdown_time {
  border-radius: 5px;

  width: 100%;

  height: 40px;
  color: #404246c7;
  padding-left: 20px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0.5px solid #a8a8a8;
  background: transparent
    url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z' fill='black'/></g></svg>")
    no-repeat;
  background-position: right 5px top 50%;
}

.dop_section {
  display: block;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgba(64, 66, 70, 0.2);
  border-radius: 100%;
  border: 1px solid #e5e5e59e;
  margin: auto;
  margin-bottom: 10px;
  margin-top: -10px;
}

.beauty_button_confirm,
.addService {
  display: initial;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgba(64, 66, 70, 0.2);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
  margin-bottom: 15px;
}

.addService {
  display: flex;
  min-width: 50px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 0;
  border: none;
}
.addNewService {
  min-width: 50px;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 17px;
  font-weight: bold;
}

.input_time {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}

.input_time label {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;
  color: #404246;
  opacity: 0.7;
}

.label_order {
  text-align: start;

  margin-right: 15px;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--GothamProRegular);
  color: #404246;
  margin-bottom: 20px;
  border-bottom: 1px solid black;
  border: 0.5px solid #a8a8a8;
  padding: 10px 10px;
  border-radius: 5px;
  margin-top: 2px;
  width: 100%;
}

.input_time input,
.input_time textarea {
  padding: 5px 10px;
  height: 40px;
  border: 1px solid#A8A8A8;
  border-radius: 5px;
  background: none;
  opacity: 0.7;
  width: inherit;

  text-align: center;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.dropdown_date {
  margin-right: 8px;
}

.input_time label:focus,
.input_time input :focus {
  opacity: 1;
}

.order_done_status1,
.order_done_status0 {
  font-family: var(--GothamProRegular);

  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  /* Малиновый */
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
}

.order_done_status2 {
  color: #e50000;
  position: relative;
  font-family: var(--GothamProRegular);
  padding-bottom: -50px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: flex-end;
  text-align: center;
}

.wrapModalConfirm {
  position: relative;
  width: 100%;
  height: 100%;
}

.modalConfirm {
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  padding: 10px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;
  width: 300px;
  height: 170px;
}
.modalConfirmMessage {
  font-family: var(--GothamProRegular);
  font-size: 19px;
  margin-top: 30px;
  text-align: center;
}
.modalConfirmButton {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  width: 100%;
}
.modalConfirmButton button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 123px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgb(64 66 70 / 20%);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
}

.topOrderDate {
  border-bottom: 1px solid #404246;
  position: relative;
  bottom: -17px;
}
.topOrderDate div {
  display: flex;
  justify-content: flex-end;
}

.item_master_serv_image {
  position: relative;
  /* margin-left: 4px; */
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 50%;
  width: 68px;
  height: 68px;
  /* margin-top: 3px; */
  /* margin-bottom: 3px; */
  margin: auto;
  margin-left: 4px;
  /* margin-top: 3px;
  margin-bottom: 3px; */
}

.item_master_serv_image img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  /* margin-top: 4px; */
  margin-left: 2px;
  margin-top: 2px;
}

.item__bshop2 {
  width: 320px;
  background: #f3f3f3;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #edcd73, #ff015f);
  background-origin: border-box;
  border: 0.4px solid transparent;
  border-radius: 5px;
  border-radius: 40px 5px 5px 5px;
  box-shadow: 0 6px 7px 1px rgb(34 60 80 / 16%);
  display: inline-flex;
  margin-bottom: 30px;
  padding-top: 8px;
  padding-bottom: 12px;
  margin-top: 24px;
}

.card {
  display: block;
}

.cardDecline {
  opacity: 40%;
  filter: grayscale(100%);
}
.cardComplete {
  opacity: 50%;
}
