.reg_wrapper {
  /* position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 63px;
}

.reg_title,
.forgetpassword {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;

  text-align: center;
  letter-spacing: 0.02em;
  margin-top: 20px;
  /* Малиновый */

  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.reg_body {
  position: relative;
  height: 85vh;
}
.registration_form {
  margin-top: 32px;
  text-align: center;
  color: #5c5c66;
  font-size: 90%;
}

.popup-info-partner {
  position: absolute;
  width: 100%;
  background: #fff;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 18px;
  border-radius: 12px 12px 0 0;
  animation: show;
  animation-duration: 0.5s;
  z-index: 5;
}

.inputgroup {
  margin-bottom: 10px;
  text-align: center;
}

.reg_placeholder_phone {
  border: none; /* Убираем рамку */
  outline: none; /* Убираем свечение в Chrome и Safari */
  -webkit-appearance: none; /* Убираем рамку в Chrome и Safari */
  width: 250px; /* Ширина поля */
  height: 38px;
  border-radius: 5px 0 0 5px;
  padding-left: 24px;
}

.reg_placeholder {
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  width: 288px;
  height: 40px;
  margin-top: 16px;
  padding-left: 24px;
  color: #282c34;
}

.reg_placeholder2 {
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  width: 288px;
  height: 40px;
  margin-bottom: 10px;
  padding-left: 24px;
  color: #282c34;
}

.reg_btn {
  border: 1px;
  border-image-source: linear-gradient(266.64deg, #f4a582 99.93%, #d432d8 -0.07%, #ff608d 51.49%);
  border-radius: 5px;
  width: 159px;
  height: 40px;
  margin-top: 55px;
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  color: #f3f3f3;
  display: inline;
}

.reg_abs {
  border: 1px;
  border-image-source: linear-gradient(266.64deg, #f4a582 99.93%, #d432d8 -0.07%, #ff608d 51.49%);
  border-radius: 5px;
  width: 260px;
  height: 40px;
  font-size: 20px;
  font-weight: 300;
  border: none;
  background-color: var(--mainColor);
  color: #ff608d;
  display: inline;
  margin-top: 20px;
  text-decoration: none;
}
.reg_btn:active {
  opacity: 0.6;
}
.inputGroup {
  display: flex;
  justify-content: center;
}
.inputGroup a {
  color: #ff608d;
  font-size: 20px;
  margin-top: 20px;
  display: block;
  text-decoration: none;
}
.input {
  color: #a8a8a8;
}
.error {
  color: red;
  margin: 5px;
}
.p1 {
  text-align: center;
  margin-top: -20px;
  text-decoration-line: none;
  border-bottom: 1px solid;
  padding-bottom: 3px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}
.p2 {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 3px;
  width: 95px;
  margin-bottom: 5px;
  display: inline-block;
}
.p3 {
  text-align: center;
  padding-bottom: 3px;
  width: 130px;
  margin-bottom: 5px;
  display: inline-block;
}
.footer {
  text-align: center;
  margin-top: 55px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0px;
  text-align: center;
  width: 34px;
}

.reg_reset_btn {
  width: 90px; /* Ширина кнопки */
  height: 38px; /* Высота кнопки */
  border: none; /* Убираем рамку */
  background: url(3.png) no-repeat 1%; /* Параметры фона */
  background-size: 38px 38px;
  display: inline-block;
  border-radius: 0 5px 5px 0;
}

.PhoneInputReset {
  min-height: 20px; /* Минимальная высота */
  display: flex;
  margin: 16px auto auto auto;
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  width: 288px;
  height: 40px;
  margin-top: 16px;
  padding-left: 0px;
  color: #282c34;
}

.choose-page {
  width: 100%;
  height: 100vh;
}

.choose-wrapper {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.choose-page-title {
  padding: 25px 0;
}

.choose-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.choose-items > a {
  text-decoration: none;
  color: black;
}

.choose-item-main {
  width: 160px;
  height: 180px;
  background-color: #fff;
  border-radius: 18px;
  box-shadow: 3px 3px 10px rgb(139, 139, 255);
  text-align: center;
  margin-bottom: 22px;
  cursor: pointer;
}
.wrapper-img-choose-page {
  width: 110px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.wrapper-img-choose-page > img {
  margin-top: 18px;
  width: 110px;
  margin-bottom: 8px;
}

.popup-info-partner {
  position: absolute;
  width: 100%;
  background: #fff;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 18px;
  border-radius: 12px 12px 0 0;
  animation: show;
  animation-duration: 0.5s;
}

.popup-info-partner-text {
  font-size: 12px;
  margin-bottom: 105px;
}

.popup-info-partner-text > p:nth-child(odd) {
  padding: 5px 0;
  font-weight: 700;
}

@keyframes show {
  0% {
    bottom: -150px;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}

.btn_info_for_partners {
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border: none;
  border-radius: 8px 8px 0 0;
  /* bottom: 20px; */
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  height: 60px;
  left: 0;
  outline: none;
  padding: 8px 0;
  position: fixed;
  width: 100%;
}

.btn_closePopUpPartner {
  background: none;
  color: rgb(43, 40, 255);
  outline: none;
  border: none;
  font-size: 16px;
  align-self: flex-end;
  padding: 10px;
}

.inner_text {
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 323px;
  padding: 24px 32px;
}
.inner_text_title {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  text-align: center;
  letter-spacing: 0.02em;

  color: #f3f3f3;
  margin-bottom: 8px;
}
.inner_text_subtitle {
  font-family: var(--GothamProRegular);

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  /* or 112% */

  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.02em;

  color: #f3f3f3;
}

.forgetpassword {
  margin: 0 auto;
  border: none;
}
.fill{
  height: 150px;
}
select{
  display: block;
}