* {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  /* font-size: 14px; */
  line-height: 16px;
}
.header,
.headerEdit {
  margin-top: 12px;
  margin-left: 24px;
  display: flex;
  margin-right: 24px;
}
.headerEdit {
  display: block;
}
.headerEdit label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.headerEdit span {
  margin-top: 10px;
  color: blue;
  font-family: var(--GothamProRegular);
}
.labelInfoMaster {
  margin-top: 10px;
}

.labelInfoMaster div {
  margin-bottom: 29px;
  min-width: 90px;
  padding-bottom: 0px;
}

.labelInfoMaster div:nth-child(3) {
  font-size: 15px;
  margin-bottom: 23px;
}
.labelInfoMaster div:nth-child(2) {
  padding-top: 4px;
}
.labelInfoMaster div:nth-child(4) {
  padding-top: 0px;
}

.labelInfoMaster {
  font-family: var(--GothamProRegular);
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  flex-direction: column;
  color: #404246;
  position: relative;
}
.inner_request {
  font-family: var(--GothamProRegular);
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  border-bottom: 1px solid #404246;
  display: flex;
  align-items: center;
  text-align: center;
  color: #404246;
  margin: 0 23px;
  position: relative;
}
.inner_request div {
  margin-bottom: 8px;
  width: 100%;
  color: #404246;
  position: relative;
}
.menu_item_tab {
  padding: 0 4px;
}
.inner_request div:active {
  opacity: 0.7;
}
.inner_request_active {
  position: absolute !important;
  width: 100%;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  height: 5px;
  left: 0;
  bottom: -27px;
}

.masterEdit {
  margin-top: 10px;
  width: 80%;
}

.titleBalance {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #404246;
  margin: 0 53px;
  margin-bottom: 23px;
}
.infoEdit {
  display: flex;
  margin-top: 20px;
  border-top: 1px solid #bababa;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid #bababa;
}
.masterEdit .name_person,
.balanceEdit {
  box-sizing: border-box;
  padding-bottom: 10px;
  border-bottom: 1px solid #bababa;
  width: 100%;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}
.balanceEdit select {
  border-radius: 5px;

  width: 100%;
  height: 35px;

  color: #000;
  padding-left: 20px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0.5px solid #a8a8a8;
  background: transparent
    url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z' fill='black'/></g></svg>")
    no-repeat;
  background-position: right 5px top 50%;
}
.avatar,
.avatarEdit {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 89px;
  height: 89px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 50px;
  margin-right: 16px;
}
.headerEdit span:active,
.wrapButtonEdit:active {
  opacity: 0.4;
}
.avatarEdit::after {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  content: '\002B';
  background: #ff608d;
  position: absolute;
  top: 70%;
  left: 79%;
  border: 2px solid #fff;
  border-radius: 50px;
  font-size: 19px;
  color: #fff;
}
.avatar img,
.avatarEdit img {
  overflow: hidden;
  border-radius: 50px;
  object-fit: cover;
  height: 90%;
  width: 90%;
}
.name_person {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  word-wrap: break-word;
  color: #404246;
}

.proffesion_person {
  font-family: var(--GothamProRegular);

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */
  margin-top: 10px;
  color: #404246;
}

.info_subscribes,
.info_edit_master {
  display: flex;
  margin-top: 26px;
  font-family: var(--GothamProRegular);
}
.info_subscribes {
  margin-top: 17px;
  flex-direction: column;
}
.info_edit_master {
  margin-top: 19px;
}

.balanceEdit:active,
.activeClick:active {
  opacity: 0.4;
}
.activeClick {
  display: flex;
  margin-bottom: 8px;
  flex-direction: column;
  position: relative;
}

.services_name {
  margin-top: 6px;
  font-weight: bold;
  font-size: 14px;
}
.services_add_name {
  margin-top: 3px;
}
.editIcon {
  position: absolute;
  left: -20px;
  top: 6px;
  color: red !important;
}
.serviceEdit {
}

.category_name {
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 4px;
}

.info_subscribes div {
  display: flex;
  margin-right: 9px;
  font-size: small;
}

.icon_info_subscribes {
  margin-right: 6px;
}

.icon_info_balans {
  margin-right: 6px;
  margin-top: -5px;
}
.buttons_person {
  display: flex;
  margin: 0 23px;
  margin-top: 24px;
  margin-bottom: 16px;
  justify-content: space-between;
}

.button_subscribe,
.button_write,
.button_search {
  padding: 8px 16px;
  gap: 10px;
  font-family: var(--GothamProRegular);
  width: 138px;
  height: 32px;
  position: relative;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  margin-right: 5px;
  color: #404246;
}

.button_subscribe div,
.button_search div {
  position: absolute;
  background-color: var(--mainColor);
  height: 89%;
  top: 2px;
  width: 96.5%;
  left: 1.5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .button_subscribe:active,
.button_write:active,
.button_search:active {
  opacity: 0.6;
} */

.button_write:active {
  opacity: 0.6;
}

.button_write {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #f3f3f3;
  opacity: 1;
}

.button_search {
  width: 55px;
}
.button_search div {
  width: 93%;
}

.content {
  margin: 0 24px;
  padding-bottom: 91px;
}
.content_address {
  display: flex;
  margin-top: 17px;
  margin-bottom: 28px;
  align-items: center;
}
.content_address_geo {
  margin-right: 10px;
}

.content_address_text {
  margin-right: 11%;
  color: #404246;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 0.4px solid #c5bebe;
}

.nonactive_tab {
  opacity: 0.5;
}

.name_tabs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  font-family: var(--GothamProRegular);

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  color: #404246;
}
.name_tabs {
  border-bottom: 1px solid;
  padding-bottom: 13px;
}
.name_tabs div {
  position: relative;
  text-align: center;
}

.active_tab::before {
  position: absolute;
  content: '';
  width: 100%;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  height: 5px;
  left: 0;
  top: 27px;
}

.tab {
  margin-top: 24px;
}

.tab_blog {
  display: flex;
  flex-wrap: wrap;
}
.tab_blog div img {
  width: 100%;
  height: 100%;
}
.tab_blog div {
  width: calc(100% / 3 - 1px);
  height: 30vw;
}

.buttonBeauty {
  font-size: 11px;
  background: none;
  border: none;
  color: #000;
  font-family: var(--GothamProRegular);
}
.wrapButtonEdit {
  background-clip: padding-box, border-box;
  background-image: linear-gradient(var(--mainColor), var(--mainColor)),
    linear-gradient(90deg, #edcd73, #ff015f);
  background-origin: border-box;
  border: 1px solid transparent;
  border-radius: 5px;
  height: 25px;
  margin-top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 113px;
}

.linkRec {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: none;
  font-weight: bold;
  padding-bottom: 10px;
}

.wrapperButtons {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 320px) {
  .header,
  .content {
    margin: 0 5px;

    margin-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .button_subscribe,
  .button_write,
  .button_search {
    font-size: 12px;
  }
  .buttons_person {
    margin: 0 5px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

.container {
  margin-top: 40px;
}

.container .nav {
  position: relative;
}
.container .nav a {
  color: #404246;
}
.container .nav li {
  display: inline;
  color: #aaaaaa;
  font-size: 11px;
  font-family: 'proxima_nova_bold';
  text-transform: lowercase;
  /* letter-spacing: 3px; */
  margin-left: 10px;
  /* border-bottom: 1px solid #aaaaaa; */
  padding-bottom: 10px;
  margin-right: 10px;
  font-family: 'Gotham Pro Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
}
a {
  margin: 0;
  padding-bottom: 10px;
}
.nav li:hover a {
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(
      to left,
      rgb(212, 50, 216) -0.07%,
      rgb(255, 96, 141) 51.49%,
      rgb(244, 165, 130) 99.93%
    )
    1 / 1 / 0 stretch;
  color: #404246;
  font-weight: 800;
}

.ftime {
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(
      to left,
      rgb(212, 50, 216) -0.07%,
      rgb(255, 96, 141) 51.49%,
      rgb(244, 165, 130) 99.93%
    )
    1 / 1 / 0 stretch;
  color: #404246;
  font-weight: 800 !important;
}

.uv {
  /* display: inline-flex; */
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #aaaaaa;
}

.myRequests {
  text-align: center;
  margin-top: 40px;
}

.exitButton {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  font-family: var(--GothamProRegular);
  padding-bottom: 100px;
}
.wrap_title_edit {
  display: flex;
  justify-content: space-between;
}
.exitButton button,
.saveButton button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 132px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  display: flex;
  text-align: center;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 20px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgb(64 66 70 / 20%);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
}
.saveButton button {
  margin-left: 0;
  margin-right: 23px;
  width: 101px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  display: flex;
  text-align: center;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.servButton {
  display: flex;
  justify-content: center;
  /* margin-top: 10px; */
  font-family: var(--GothamProRegular);
  /* padding-bottom: 100px; */
}

.servButton button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 132px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-left: 20px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgb(64 66 70 / 20%);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
}

.getApplicationsText {
  font-family: var(--GothamProRegular);
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #bababa;
  color: #404246;
  padding-left: 24px;
}

.root_add_balance {
  display: flex;
  font-family: var(--GothamProRegular);
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  left: calc(50% - 289px / 2 - 0px);
  top: calc(50% - 132px / 2 + 40.5px);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #404246;
}
.root_add_balance a {
  color: #404246;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;

  position: absolute;
  width: 190px;
  height: 34px;
  left: calc(50% - 190px / 2 - 0px);
  top: calc(50% - 34px / 2 + 40.5px);

  /* Малиновый */

  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;

  font-family: var(--GothamProRegular);

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;
  /* identical to box height, or 100% */

  color: #ffffff;
}

.wrapModalConfirm {
  position: relative;
  width: 100%;
  height: 100%;
}

.modalConfirm {
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  padding: 10px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;
  width: 300px;
  height: 200px;
}
.modalConfirmMessage {
  font-family: var(--GothamProRegular);
  font-size: 19px;
  margin-top: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.modalConfirmButton,
.modalConfirmButtonLink {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  width: 100%;
}

.modalConfirmButtonLink {
  margin-top: 0px;
}
.modalConfirmButton button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 123px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgb(64 66 70 / 20%);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
}

.modalConfirmButtonLink button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 160px;
  height: 42px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgb(64 66 70 / 20%);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
}
.balanceEdit input {
  background: none;
  border: none;
  padding: 1px 2px;
  font-size: 16px;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  font-family: var(--GothamProRegular);
  width: 95%;
}
.editPen {
  position: absolute;
  display: inline-block;
  right: 0;
}

.titleEditProfile {
  margin-top: 10px;
  color: blue;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  color: #404246;
}

.title_balance_info {
  margin-left: 51px;
  display: flex;
  align-items: center;
  margin-top: 38px;
}

.title_balance_info div {
  display: inline-block;
  margin-left: 28px;
}

.payment_zone {
  margin: 10px 10px;
}
.amount {
  background: #fff;
  font-size: 18px;
  border: 1px solid #00000033;
  justify-content: space-between;
  display: flex;
  align-items: center;
  color: #333;
}

.amount input {
  padding: 10px;
  text-align: center;
  font-weight: bold;
  outline: none;
  border: none;
  font-size: 18px;
  width: 100%;
}
.amount span {
  padding: 5px;
  font-weight: bold;
  justify-self: end;
  font-size: 24px;
  color: #888;
}
.payment_zone button {
  margin: 10px 0;
}

.shortLink {
  font-style: normal;
  font-size: 19px;
  line-height: 20px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: none;
  font-weight: bold;
  padding-bottom: 10px;
  text-align: center;
  width: 80%;
  word-break: break-all;
}

.modalTitleConfirm {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  word-wrap: break-word;
  color: #404246;
  margin-bottom: 10px;
  margin-top: 10px;
}
